import React from 'react';
import './WhyChooseUs.css';
import truckDeliveryImage from '../../../assets/images/truck-delivery.svg';
import check from '../../../assets/images/check.svg';

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us-container">
      <div className="why-choose-us-content">
        <h1 className="why-choose-us-title">Why Choose Rove?</h1>
        
        <div className="benefits-container">
          <div className="benefit-item">
            <div className="check-icon">
              <img src={check} alt="Check Icon" width="35" height="35"/>
            </div>
            <div className="benefit-content">
              <h2 className="benefit-title">0% Commission for Drivers</h2>
              <p className="benefit-description">Happy drivers! Better Service! Because we value your experience.</p>
            </div>
          </div>

          <div className="benefit-item">
            <div className="check-icon">
              <img src={check} alt="Check Icon" width="35" height="35"/>
            </div>
            <div className="benefit-content">
              <h2 className="benefit-title">Lower Costs for Customers</h2>
              <p className="benefit-description">Enjoy affordable deliveries with no hidden charges.</p>
            </div>
          </div>

          <div className="benefit-item">
            <div className="check-icon">
              <img src={check} alt="Check Icon" width="35" height="35"/>
            </div>
            <div className="benefit-content">
              <h2 className="benefit-title">Transparent & Fair Pricing</h2>
              <p className="benefit-description">No surge pricing or unexpected fees. Ever!</p>
            </div>
          </div>

          <div className="benefit-item">
            <div className="check-icon">
              <img src={check} alt="Check Icon" width="35" height="35"/>
            </div>
            <div className="benefit-content">
              <h2 className="benefit-title">Reliable & Fast Deliveries</h2>
              <p className="benefit-description">Optimized routes for quick and efficient service.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="illustration-container">
        <img 
          src={truckDeliveryImage} 
          alt="Delivery truck with driver and customer" 
          className="delivery-illustration" 
        />
      </div>
    </div>
  );
};

export default WhyChooseUs;