import React, { useState } from 'react';
import './Hero.css';
import logo from '../../../assets/images/logo.svg';
import apple from '../../../assets/images/apple.svg';
import google from '../../../assets/images/google.svg';
import heroImage from '../../../assets/images/hero-image.png';

const Hero = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      setMobileMenuOpen(false);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="hero-container">
      <div className="hero-background">
        {/* Header */}
        <header className="header">
          {/* Left side - Icon */}
          <div className="logo-container">
            <div className="logo">
              <img src={logo} alt="Logo" className="logo-image" />
            </div>
          </div>
          
          {/* Mobile menu button */}
          <button 
            className="mobile-menu-button" 
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <span className="menu-icon"></span>
            <span className="menu-icon"></span>
            <span className="menu-icon"></span>
          </button>
          
          {/* Right side - Navigation buttons */}
          <nav className={`nav ${mobileMenuOpen ? 'nav-open' : ''}`}>
            <ul className="nav-list">
              <li className="nav-item">
                <button className="nav-button" onClick={() => scrollToSection('services')}>
                  About Us
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-button" onClick={() => scrollToSection('faq')}>
                  FAQ
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-button" onClick={() => scrollToSection('contact')}>
                  Contact Us
                </button>
              </li>
            </ul>
          </nav>
        </header>
        
        {/* Banner content goes here */}
        {/* <div className="static-banner">
          <div className="banner-content">
            <div className="banner-text">
              <i className="fas fa-star"></i>
              <span>New to Rove? Your first ride is 50% off! Code: <span className="promo-code-banner">WELCOME50</span></span>
            </div>
          </div>
        </div>
         */}

        {/* Hero content goes here */}
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="hero-title">Fast. Affordable. Zero Commission. Rove Your Way!</h1>
            <p className="hero-subtitle">Say goodbye to hidden fees. With Rove, drivers earn more, and you pay less.</p>
            

            <div className="app-ribbon">
              <span className="ribbon-text">Available on</span>
              <div className="app-stores">
                <div className="app-store">
                  <img src={apple} alt="appstore" className='apple-icon'/>
                  <span className="store-name">App Store</span>
                </div>
                <div className="app-store">
                  <img src={google} alt="playstore" className='google-play-icon'/>
                  <span className="store-name">Google Play</span>
                </div>
              </div>
            </div>
            {/* <p className="welcome-text">
              New to Rove? Your first ride is 50% off! Code: <span className="promo-code">WELCOME50</span>
            </p> */}
          </div>

          {/* Hero image */}
          <div className="hero-image-container">
            <img src={heroImage} alt="Rove App" className="hero-image" />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Hero;