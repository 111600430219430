import React, { useState } from 'react';
import './FAQ.css';
import faqImage from '../../../assets/images/faq.svg';

const FAQ = () => {
  const [activeId, setActiveId] = useState(null);

  const faqItems = [
    {
      id: 1,
      question: "What makes Rove unique?",
      answer: "Rove offers a 0% commision platform for drivers. Ensuring happier drivers and better service for you."
    },
    {
      id: 2,
      question: "How do I book a delivery?",
      answer: "Open the rove app. Enter your location. Choose a vehicle and confirm your booking."
    },
    {
      id: 3,
      question: "How do I pay for a trip?",
      answer: "You pay the driver directly via cash or UPI – no middlemen, no hidden fees."
    },
    {
      id: 4,
      question: "What types of vehicles are available?",
      answer: "We support bikes, autos, mini trucks, and more, depending on your delivery needs."
    },
    {
      id: 5,
      question: "How do I become a driver?",
      answer: "Sign up on the Rove Drive Partner app, complete your profile and submit your documents for verification. We'll take care of the rest."
    },
  ];

  const toggleAnswer = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  return (
    <div className="faq-container">
      <div className="faq-content">
        <h1 className="faq-title">FAQs</h1>
        
        <div className="faq-list">
          {faqItems.map((item) => (
            <div key={item.id} className="faq-item">
              <h2 
                className={`faq-question ${activeId === item.id ? 'active' : ''}`}
                onClick={() => toggleAnswer(item.id)}
              >
                {item.id}. {item.question}
                <span className="faq-icon">{activeId === item.id ? '−' : '+'}</span>
              </h2>
              <div className={`faq-answer ${activeId === item.id ? 'active' : ''}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="faq-illustration-container">
        <img 
          src={faqImage} 
          alt="Delivery vehicles illustration" 
          className="faq-illustration" 
        />
      </div>
    </div>
  );
};

export default FAQ;