import React from 'react';
import './Footer.css';
import apple from '../../../assets/images/apple.svg';
import google from '../../../assets/images/google.svg';
import logo from '../../../assets/images/logo.svg';
import facebook from '../../../assets/images/facebook.svg';
import instagram from '../../../assets/images/instagram.svg';
import whatsapp from '../../../assets/images/whatsapp.svg';
import phone from '../../../assets/images/phone.svg';
import email from '../../../assets/images/email.svg';
import location from '../../../assets/images/location.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className='footer-left'>
        <div className="footer-logo-container">
          <div className="footer-logo">
            <img src={logo} alt="Logo" className="footer-logo-image" />
          </div>
        </div>
        
        <div className="footer-contact">
          <h3 className="footer-contact-title">Contact Us</h3>
          <div className="footer-contact-info">
            <div className="footer-contact-item">
              <img src={phone} alt="Phone" className="footer-contact-icon" />
              <span>+91 93163 99238</span>
            </div>
            <div className="footer-contact-item">
              <img src={email} alt="Email" className="footer-contact-icon" />
              <span>contact@gorove.in</span>
            </div>
            <div className="footer-contact-item">
              <img src={location} alt="Location" className="footer-contact-icon" />
              <span>Ahmedabad, India</span>
            </div>
          </div>
        </div>
        
        <div className="download-section">
          <p className="download-text">Download the app now!</p>
          <div className="footer-app-stores">
            <div className="footer-app-store">
              <img src={apple} alt="App Store" className='footer-apple-icon'/>
              <span className="footer-store-name">App Store</span>
            </div>
            <div className="footer-app-store">
              <img src={google} alt="Google Play" className='footer-google-play-icon'/>
              <span className="footer-store-name">Google Play</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className='footer-right'>
        <div className="footer-links-section">
          <div className="footer-links-column">
            <h3 className="footer-column-title">Quick Links</h3>
            <ul className="footer-link-list">
              <li onClick={()=>{scrollToSection('about')}}>About Us</li>
              <li onClick={()=>{scrollToSection('services')}}>Services</li>
              <li onClick={()=>{scrollToSection('products')}}>Products</li>
              <li onClick={()=>{scrollToSection('contact')}}>Contact</li>
            </ul>
          </div>
        </div>
        
        <div className="footer-legal">
          <div className="footer-links">
            <a href="https://policies.gorove.in/privacy-policy" className="footer-legal-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <a href="https://policies.gorove.in/terms-of-service" className="footer-legal-link" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          </div>
          
          <div className="footer-social">
            <a href="https://www.facebook.com/people/Gorovein/61572949446771/" className="footer-social-icon" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/gorove.in/" className="footer-social-icon" target="_blank" rel="noopener noreferrer">
              <img src={instagram} alt="Instagram" />
            </a>
            <a href="https://w.app/rove" className="footer-social-icon" target="_blank" rel="noopener noreferrer">
              <img src={whatsapp} alt="WhatsApp" />
            </a>
            <a href="https://www.linkedin.com/company/gorove/" className="footer-social-icon" target="_blank" rel="noopener noreferrer">
              <img src={whatsapp} alt="LinkedIn" />
            </a>
          </div>
        </div>
        
        <div className="footer-bottom">
          <div className="registered-office-divider"></div>
          <div className="registered-office-text">
            Registered Office: <br/>
            597/B/3, Bhagat Saw Mill, Comp, BhikshukGruh Bus St, Odhav, Ahmadabad City, Ahmedabad, Gujarat<br/>
            CIN: U63119GJ2025PTC158257
          </div>
          <div className="copyright-text">
            © {currentYear} Rovvy Technologies Pvt. Ltd. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;