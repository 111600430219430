import React from 'react';
import './CurrentlyOperatingIn.css';
import locationPinIcon from '../../../assets/images/location-pin.svg'; // Update path as needed

const CurrentlyOperatingIn = () => {
  return (
    <div className="operating-container">
      <div className="operating-content">
        <div className="location-icon-container">
          <img src={locationPinIcon} alt="Location pin" className="location-icon" />
        </div>
        <h2 className="operating-title">Currently Operating In Ahmedabad</h2>
        <p className="operating-subtitle">We are excited to serve Ahmedabad with more cities coming soon!</p>
      </div>
    </div>
  );
};

export default CurrentlyOperatingIn;