import React, { useState } from 'react';
import './HowItWorks.css';
import locationIcon from '../../../assets/images/location-icon.svg';
import vehicleIcon from '../../../assets/images/vehicle-icon.svg';
import bookIcon from '../../../assets/images/book-icon.svg';
import paymentIcon from '../../../assets/images/payment-icon.svg';
import cash from '../../../assets/images/cash.svg'
import clock from '../../../assets/images/clock.svg'
import zeropercent from '../../../assets/images/zeropercent.svg'
import profile from '../../../assets/images/profile.svg'

const HowItWorks = () => {
  const [activeTab, setActiveTab] = useState('customers');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="how-it-works-container">
      <h1 className="how-it-works-title">How Rove Works?</h1>
      
      <div className="tabs-container">
        <button 
          className={`tab ${activeTab === 'customers' ? 'active' : ''}`}
          onClick={() => handleTabChange('customers')}
        >
          Customers
        </button>
        <button 
          className={`tab ${activeTab === 'drivers' ? 'active' : ''}`}
          onClick={() => handleTabChange('drivers')}
        >
          Drivers
        </button>
      </div>

      {activeTab === 'customers' && (
        <div className="steps-container">
          <div className="step-card">
            <div className="icon-container">
              <img src={locationIcon} alt="Location" className="step-icon" width="32" height="32" />
            </div>
            <h2 className="step-title">Set Your Location</h2>
            <p className="step-description">
              Open the Rove app and enter your pickup & drop-off location to get started. 
            </p>
          </div>

          <div className="step-card">
            <div className="icon-container">
              <img src={vehicleIcon} alt="Vehicle" className="step-icon" width="32" height="32" />
            </div>
            <h2 className="step-title">Pick Your Vehicle</h2>
            <p className="step-description">
              Pick the vehicle that fits your needs (2 wheeler, 3 wheeler, mini truck, and more...)
            </p>
          </div>

          <div className="step-card">
            <div className="icon-container">
              <img src={bookIcon} alt="Book" className="step-icon" width="32" height="32" />
            </div>
            <h2 className="step-title">Book & Track</h2>
            <p className="step-description">
              Get an instant price, book your ride, and track your delivery in real time.
            </p>
          </div>

          <div className="step-card">
            <div className="icon-container">
              <img src={paymentIcon} alt="Payment" className="step-icon" width="32" height="32" />
            </div>
            <h2 className="step-title">Pay The Driver</h2>
            <p className="step-description">
              Pay directly to the driver. No hidden fees. Honest Pricing!
            </p>
          </div>
        </div>
      )}

      {activeTab === 'drivers' && (
         <div className="steps-container">
         <div className="step-card">
           <div className="icon-container">
             <img src={profile} alt="Location" className="step-icon" width="32" height="32" />
           </div>
           <h2 className="step-title">Sign Up</h2>
           <p className="step-description">
            Log in, enter your vehicle details, complete KYC and start receiving ride requests.
           </p>
         </div>

         <div className="step-card">
           <div className="icon-container">
             <img src={zeropercent} alt="Vehicle" className="step-icon" width="32" height="32" />
           </div>
           <h2 className="step-title">0% Comission</h2>
           <p className="step-description">
            Keep 100% of your earnings with no comission cuts.
           </p>
         </div>

         <div className="step-card">
           <div className="icon-container">
             <img src={clock} alt="Book" className="step-icon" width="32" height="32" />
           </div>
           <h2 className="step-title">Flexible Work Hours</h2>
           <p className="step-description">
            Set your schedule, accept rides anytime, and work at your own pace.
           </p>
         </div>

         <div className="step-card">
           <div className="icon-container">
             <img src={cash} alt="Payment" className="step-icon" width="32" height="32" />
           </div>
           <h2 className="step-title">Instant Payout</h2>
           <p className="step-description">
           Get paid instantly, directly to your account—no waiting, no hassle.
           </p>
         </div>
       </div>
      )}
    </div>
  );
};

export default HowItWorks;