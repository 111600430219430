import './App.css'
import Hero from './components/sections/Hero'
import HowItWorks from './components/sections/HowItWorks'
import WhyChooseUs from './components/sections/WhyChooseUs'
import CurrentlyOperatingIn from './components/sections/CurrentlyOperatingIn'
import FAQ from './components/sections/FAQ'
import Footer from './components/sections/Footer'

function App() {
  return (
    <>
      <section id="about">
        <Hero id="about" />
      </section>
      <section id="services">
        <HowItWorks />
      </section>
      <section id="products">
        <WhyChooseUs />
      </section>
      <section id="about">
        <CurrentlyOperatingIn />
      </section>
      <section id="faq">
        <FAQ />
      </section>
      <section id="contact">
        <Footer />
      </section>
    </>
  )
}

export default App
